import { Link } from "gatsby";
import React, { useEffect, useState } from "react";
import TextTransition, { presets } from "react-text-transition";

import { Layout, ContactFormDark } from "../components";

import locales from "../constants";
import Map from "../components/svg/Map";

const preparation_steps_data = [
    {
        title: "Badanie rynku w kraju docelowym",
        text: "Jeżeli rozważasz kilka krajów, możemy pomóc w procesie decyzyjnym, wykorzystując zewnętrzne narzędzia służące do analizy potencjału rynków i zarekomendować inne, które pomogą Ci dobrze przygotować się do rozpoczęcia działalności w nowym kraju.",
    },
    {
        title: "Znajomość przepisów prawnych i podatkowych w danym kraju",
        text: "Pamiętaj, że na niektóre kraje Google nakłada dodatkową prowizję do wykorzystywanego budżetu mediowego z uwagi na indywidualne opodatkowanie w danym kraju. Na przykład opłata z tytułu obowiązujących we Francji ustawowych kosztów operacyjnych wynosi 2%, które Google dolicza do swoich faktur na koniec każdego miesiąca.",
        link: {
            href: "#",
            label: "Sprawdź dodatkowe opłaty w pozostałych krajach",
        },
    },
    {
        title: "Logistyka sprzedaży",
        text: "w tym wysyłka zamówień (E-commerce)",
    },
    {
        title: "Przygotowanie strony internetowej ",
        text: "w docelowym języku i domeny (opcjonalnie) ",
    },
];

const cooperation_example_data = [
    {
        month: "Luty",
        year: 2016,
        text: "Czas na WHEN! Rozpoczęcie współpracy w ramach kampanii Google Ads dla pierwszego sklepu polskiego.",
    },
    {
        month: "Luty",
        year: 2017,
        text: "Rozszerzenie współpracy o drugi sklep polski.",
    },
    {
        month: "Styczeń",
        year: 2020,
        text: "Rozszerzyliśmy działania marketingowe o rynek włoski.",
    },
    {
        month: "Maj",
        year: 2020,
        text: "Pierwsza ekspansja zagraniczna na rynek niemiecki.",
    },
    {
        month: "Luty",
        year: 2021,
        text: "Próba rozszerzenia kampanii Google Ads na Estonię. Ostatecznie rezygnacja z kampanii online na ten rynek.",
    },
    {
        month: "Marzec",
        year: 2021,
        text: "Rozpoczęcie kampanii Google Ads dla Wielkiej Brytanii.",
    },
    {
        month: "Wrzesień",
        year: 2021,
        text: "Kontynuujemy ekspansje wchodząc na rynek francuski.",
    },
    {
        month: "Październik",
        year: 2023,
        text: "Rozszerzenie niemieckiej kampanii Google Ads na Austrię i Holandię.",
    },
    {
        month: "Styczeń",
        year: 2024,
        text: "Szukamy nowych rynków!",
    },
];

const success_path_data = [
    {
        title: "Brief - poznajmy się lepiej",
        text: "Aby stworzyć strategię działań kampanii zagranicznych musimy dokładnie zrozumieć Twój biznes oraz Twoje oczekiwania. Spotkanie lub wideokonferencja to podstawa do tego, abyśmy poznali się trochę lepiej.",
    },
    {
        title: "Analiza",
        text: "Czas na naszą analizę. W tym kroku zapoznamy się m.in z dotychczasowymi działaniami marketingowymi, ich skutecznością, a także przeprowadzimy analizy interesujących Cię rynków w oparciu o potencjały w konkretnych kanałach reklamowych.",
    },
    {
        title: "Bezpłatna strategia",
        text: "Proponujemy Ci najnowsze rozwiązania, najbardziej aktualną wiedzę oraz pakiet wielu lat naszego doświadczenia w kampaniach zagranicznych. Przedstawimy Ci podział różnych kanałów reklamowych, estymacje kosztów emisji reklam i zarekomendujemy konkretny plan działania 'na start'. Omówimy z Tobą koncept reklamowy oraz warunki współpracy. Otrzymując naszą strategię masz pewność, że zaproponowane kanały i sposoby targetowania reklamy przełożą się na zysk Twojego biznesu.",
    },
    {
        title: "Czas na wspólny podbój świata!",
        text: "Po podpisaniu umowy wdrażamy zaczynamy tworzyć kampanię na kontach reklamowych, których od początku jesteś właścicielem. Stworzymy dla Ciebie reklamy z uwzględnieniem najlepszych praktyk, które sprawdzają się w naszych innych zagranicznych kampaniach. Skonfigurujemy również pełną analitykę strony, aby móc efektywnie, systematycznie analizować wyniki prowadzonych działań marketingowych Przeanalizujemy twoją stronę www i wskażemy obszary, które mogą wymagać poprawy dla zwiększenia potencjału zysku z kampanii, a także przetłumaczymy treści reklam z najlepszymi native speakerami.",
    },
    {
        title: "Optymalizacja",
        text: "Nie lubimy stać biernie, a optymalizacja to nasze drugie imię. Na podstawie bieżących wyników, będziemy modyfikować wszelkie parametry kampanii, a budżety intensyfikować w obszarach, które przynoszą największy zwrot z inwestycji. Będziemy z Tobą w stałym kontakcie. Otrzymasz od nas dedykowane raporty tygodniowe, miesięczne oraz dostęp do spersonalizowanego, interaktywnego raportu Looker Studio.",
    },
    {
        title: "Świat czeka. Skalujemy!",
        text: "Kampania osiągnęła satysfakcjonujące ROI? Czas na jej skalowanie i kolejne rynki.",
    },
    {
        title: "Partnerstwo w sukcesie",
        text: "Twój rozwój, to nasz rozwój. Twój zysk, to nasz zysk. #whenwewin to nasza filozofia wspólnego partnerstwa w sukcesie.",
    },
];

const COUNTRIES = [
    "Czechy",
    "Słowacja",
    "Rumunia",
    "Włochy",
    "Niderlandy",
    "Francja",
    "Niemcy",
    "USA",
    "Hiszpania",
    "Estonia",
    "Polska",
    "Wielka Brytania",
    "Kenia",
    "Nigeria",
    "Japonia",
    "Kanada",
    "Austria",
    "Szwajcaria",
    "Rosja",
    "Filipiny",
];

const TEXTS = [
    "Wykonujemy dokładną analizę rynku",
    "Od początku jesteś właścicielem wszystkich kont reklamowych",
    "Należymy do 3% najlepszych agencji Google Ads w Polsce!",
    "Nasze kampanie tłumaczone są przez najlepszych native speakerów",
    "Optymalizując kampanie dbamy o Twój zwrot z inwestycji",
    "Nie zatrudniamy juniorów - kampanię optymalizują wyłącznie doświadczeni specjaliści PPC",
    "Uwielbiamy skalować biznesy i podbijać nowe kraje!",
    "Decydując się na ekspansje, nasi klienci rozwijają się z sukcesami średnio na minimum 3 nowe kraje zagraniczne",
    "Pracujesz z nami tak długo, jak tego chcesz - nasze umowy są elastyczne i posiadają miesięczny okres wypowiedzenia",
];

const ForeignCampaigns = ({ location: { pathname } }) => {
    const [scroll, setScroll] = useState(0);
    const [country_index, setCountryIndex] = useState(0);
    const [text_index, setTextIndex] = useState(0);
    const [text_index_loop, setTextIndexLoop] = useState(0);

    useEffect(() => {
        window.addEventListener("scroll", () => {
            const section =
                document.getElementsByClassName("home-google-agency")[0];

            if (section) {
                setScroll(
                    section.offsetTop - section.offsetHeight - window.scrollY
                );
            }
        });
    }, []);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCountryIndex((index) => index + 1);
            setTextIndex((index) => index + 1);
        }, 2000);
        return () => clearTimeout(intervalId);
    }, []);

    useEffect(() => {
        if (text_index_loop === 9) {
            setTextIndexLoop(1);
        } else {
            setTextIndexLoop((prev) => prev + 1);
        }
    }, [text_index]);

    return (
        <Layout
            seo={{
                title: "Kampanie PPC na rynki zagraniczne",
                description:
                    "Dzięki naszym kampaniom reklamowym na rynki zagraniczne, będziesz mógł zwiększyć swoją widoczność wśród klientów na całym świecie. Z nami osiągniesz sukces w ekspansji na nowych rynkach i zwiększysz swoje szanse na zdobycie nowych klientów. Profesjonalne kampanie PPC na rynki zagraniczne na platformach takich jak Google Ads, Facebook Ads, Linkedin Ads oraz TikTok Ads.",
                href: pathname,
                lang: "pl",
            }}
        >
            <span className="anchor" id="home"></span>
            <div className="subpage-header subpage-header--single-offer">
                <h1 className="subpage-header__title foreign-campaigns__header">
                    Kampanie PPC
                    <span>na rynki zagraniczne</span>
                </h1>
            </div>

            <div className="single-offer-menu-container">
                <div className="row">
                    <div className="col-lg-3">
                        <Link to="/uslugi/#ppc">
                            <div className="back-wrapper">
                                <span className="back-wrapper__arrow"></span>
                                <p className="back-wrapper__title">Usługi</p>
                            </div>
                        </Link>
                        <p className="nav-title">Specjalizacje:</p>
                        <ul>
                            <li>
                                <Link to="/audyt-kampanii-google-facebook-ads/">
                                    Audyt kampanii <br /> Google/Facebook Ads
                                </Link>
                            </li>
                            <li>
                                <Link to="/kampanie-googleads-search/">
                                    Kampanie Google Ads search
                                </Link>
                            </li>
                            <li>
                                <Link to="/kampanie-wideo-na-youtube/">
                                    Kampanie Youtube Ads
                                </Link>
                            </li>
                            <li>
                                <Link to="/kampanie-w-sieci-reklamowej-gdn/">
                                    Kampanie GDN
                                </Link>
                            </li>
                            <li>
                                <Link to="/kampanie-produktowe-product-listing-ads/">
                                    Kampanie PLA
                                </Link>
                            </li>
                            <li>
                                <Link to="/tiktok-ads/">
                                    Kampanie TikTok Ads
                                </Link>
                            </li>
                            <li>
                                <Link to="/kampanie-facebook-ads/">
                                    Kampanie Facebook Ads
                                </Link>
                            </li>
                            <li>
                                <a href="#home">Kampanie na Instagramie</a>
                            </li>
                            <li>
                                <Link to="/kampanie-linkedin-ads/">
                                    Kampanie LinkedIn
                                </Link>
                            </li>
                            <li>
                                <Link to="/banery-statyczne-i-dynamiczne/">
                                    Banery statyczne i dynamiczne
                                </Link>
                            </li>
                            <li className="current">
                                <Link to="/kampanie-zagraniczne/">
                                    Kampanie PPC na rynki zagraniczne
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="foreign-campaigns">
                <section className="single-offer-section-text-left single-offer-section-text-left--cat-3">
                    <div className="row">
                        <div className="col-xl-8 offset-xl-4 col-lg-9 offset-lg-3">
                            <div className="row">
                                <div className="col-lg-8 text-col">
                                    <h2 className="text-col__title mb-40">
                                        Międzynarodowe kampanie Google Ads,
                                        Facebook Ads i inne
                                    </h2>
                                    <p className="text-col__desc">
                                        Szukasz skutecznych metod na skalowanie
                                        swojego biznesu za granicą i rzetelnego,
                                        doświadczonego partnera, który Ci w tym
                                        pomoże? Do rozpoczęcia ekspansji pomocne
                                        będzie nasze doświadczenie w realizacji
                                        zagranicznych kampanii PPC: Google Ads,
                                        Facebook Ads, Linkedin Ads oraz TikTok
                                        Ads. Jeśli planujesz wykorzystać
                                        nieograniczony potencjał sprzedaży na
                                        nowych rynkach zagranicznych i rozpocząć
                                        podbój świata, to dobrze trafiłeś.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="single-offer-section-text-right">
                    <div className="row">
                        <div className="col-xl-9 offset-xl-3 col-lg-9 offset-lg-3">
                            <div className="row align-items-center">
                                <div className="col-lg-6 img-col">
                                    <img
                                        className="img-fluid img-col__image"
                                        src={require("../assets/images/kamapnie-zagraniczne-go.jpg")}
                                        alt=""
                                    />
                                </div>
                                <div className="col-lg-6 text-col">
                                    <h2 className="text-col__title mb-40">
                                        Nasze kampanie nie
                                        <br />
                                        znają granic
                                    </h2>
                                    <p className="text-col__desc">
                                        Z sukcesami rozwijamy działalność
                                        naszych klientów na nowych rynkach,
                                        prowadząc kampanie w wielu rodzimych
                                        językach. Aby rozpocząć ekspansje
                                        zagraniczną nie musisz fizycznie
                                        przebywać w danym kraju, czy podejmować
                                        współpracy z lokalną agencją. Dla
                                        systemów reklamowych nie ma znaczenia z
                                        jakiego zakątka ziemi prowadzisz swój
                                        biznes. Wniosek jest jeden: żadnych
                                        granic!
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="single-offer-section-text-right countries">
                    <div className="row">
                        <div className="col-xl-9 offset-xl-3 col-lg-9 offset-lg-3">
                            <h4>
                                Systematycznie prowadzimy dziesiątki kampanii
                                PPC. Zobacz Państwa, w których prowadziliśmy już
                                działania reklamowe:
                            </h4>
                            <h2>
                                <TextTransition
                                    text={
                                        COUNTRIES[
                                            country_index % COUNTRIES.length
                                        ]
                                    }
                                    springConfig={presets.wobbly}
                                />
                            </h2>
                            {/* <img
                className="img-fluid"
                src={require("../assets/images/kampanie-zagraniczne-map.svg")}
                alt=""
              /> */}
                            <Map />
                            <Link
                                to="/kampanie-zagraniczne#kontakt"
                                className="btn btn-box free-consulting"
                            >
                                Skontaktuj się z nami
                            </Link>
                        </div>
                    </div>
                </section>

                <section className="single-offer-section-text-right preparation-steps">
                    <div className="row">
                        <div className="col-xl-9 offset-xl-3 col-lg-9 offset-lg-3">
                            <div className="preparation-steps__header">
                                <h2>Odpowiednie przygotowanie</h2>
                                <p>
                                    Kampanie na nowych rynkach wymagają od
                                    Ciebie podjęcia kilku, istotnych kroków.
                                    Poniżej najważniejsze z nich:
                                </p>
                            </div>
                            <div className="preparation-steps__items">
                                {preparation_steps_data?.map(
                                    ({ title, text, link }, index) => (
                                        <div className="preparation-steps__items__item">
                                            <div className="preparation-steps__items__item__wrapper">
                                                <h3>{index + 1}</h3>
                                                <div className="preparation-steps__items__item__wrapper__text">
                                                    <h4>{title}</h4>
                                                    <p>{text}</p>
                                                    {!!link && (
                                                        <a href={link?.href}>
                                                            {link?.label}
                                                        </a>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                )}
                            </div>
                        </div>
                    </div>
                </section>

                <section className="single-offer-section-text-right scaling-business">
                    <div className="row">
                        <div className="col-xl-9 offset-xl-3 col-lg-9 offset-lg-3">
                            <h1>Dlaczego warto skalować swój biznes z nami?</h1>
                            <div className="scaling-business__slider">
                                {/* <h2>Wykonujemy dokładną analizę rynku</h2> */}
                                <h2>
                                    <TextTransition
                                        text={TEXTS[text_index % TEXTS.length]}
                                        springConfig={presets.wobbly}
                                    />
                                </h2>
                                <h3>
                                    {text_index_loop}{" "}
                                    <span
                                        style={
                                            text_index_loop === 9
                                                ? { color: "#fff" }
                                                : null
                                        }
                                    >
                                        / 9
                                    </span>
                                </h3>
                            </div>
                        </div>
                    </div>
                </section>

                <section
                    className="home-google-agency"
                    style={{ backgroundPositionX: scroll }}
                >
                    <div className="single-offer-section-text-right">
                        <div className="row">
                            <div className="col-xl-9 offset-xl-3 col-lg-9 offset-lg-3">
                                <div className="container-fluid">
                                    <h2>
                                        Jesteśmy wśród <span>3%</span>{" "}
                                        najlepszych
                                        <br />
                                        agencji Google Ads w Polsce!
                                    </h2>
                                    <p>
                                        Najwyższe odznaczenie agencyjne Google
                                        Premier Partner  to ogromne wyróżnienie,
                                        które potwierdza wysoką jakość naszych
                                        usług i zaawansowane umiejętności i
                                        wiedzę w tworzeniu i optymalizacji
                                        kampanii Google Ads. Dzięki statusowi
                                        Google Partner Premier nasza agencja ma
                                        dostęp do szeregu narzędzi i funkcji,
                                        które pomogą nam jeszcze lepiej
                                        zoptymalizować Twoją kampanię.
                                    </p>
                                    <div className="home-google-agency__bottom">
                                        <a
                                            href="https://www.google.com/partners/agency?id=8558321192"
                                            className="btn btn-line btn-download-portfolio"
                                            rel="noreferrer nofollow"
                                            target="_blank"
                                        >
                                            Dowiedz się więcej
                                        </a>
                                        <img
                                            src={require("../assets/images/google-premier-partner.svg")}
                                            alt=""
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="single-offer-section-text-right example-cooperation">
                    <div className="row">
                        <div className="col-xl-9 offset-xl-3 col-lg-9 offset-lg-3">
                            <div className="example-cooperation__header">
                                <h2>
                                    8 lat - 8 krajów! Przykładowa współpraca z
                                    producentem artykułów biurowych
                                </h2>
                                <p>
                                    Nasza współpraca często zaczyna się w
                                    Polsce, ale rzadko na niej kończy. Z wieloma
                                    sukcesami prowadzimy kilkadziesiąt
                                    zagranicznych kampanii Google i Facebook Ads
                                    w rodzimych językach, rozpoczynając lub
                                    rozwijając ekspansję działalności naszych
                                    klientów w kolejnych krajach.
                                </p>
                            </div>
                            <div className="example-cooperation__schema">
                                <img
                                    src={require("../assets/images/example-cooperation.svg")}
                                    alt=""
                                />
                                {cooperation_example_data?.map(
                                    ({ month, year, text }, index) => (
                                        <div
                                            key={index}
                                            className="example-cooperation__schema__item"
                                        >
                                            <div
                                                key={index}
                                                className="example-cooperation__schema__item__text"
                                            >
                                                <h3>
                                                    {month} <span>{year}</span>
                                                </h3>
                                                <p>{text}</p>
                                                {index ===
                                                    cooperation_example_data?.length -
                                                        1 && (
                                                    <Link
                                                        to="/kampanie-zagraniczne#kontakt"
                                                        className="btn btn-box free-consulting"
                                                    >
                                                        Otrzymaj darmową
                                                        strategię
                                                    </Link>
                                                )}
                                            </div>
                                        </div>
                                    )
                                )}
                            </div>
                        </div>
                    </div>
                </section>

                <section className="single-offer-section-text-right success-path">
                    <div className="row">
                        <div className="col-xl-9 offset-xl-3 col-lg-9 offset-lg-3">
                            <div className="example-cooperation__header">
                                <h2>Sprawdź ścieżkę naszego sukcesu</h2>
                            </div>
                            <div className="success-path__schema">
                                <img
                                    src={require("../assets/images/success-path.svg")}
                                    alt=""
                                />
                                {success_path_data?.map(
                                    ({ title, text }, index) => (
                                        <div
                                            key={index}
                                            className="success-path__schema__item"
                                        >
                                            <div className="success-path__schema__item__number">
                                                <h4>{index + 1}</h4>
                                            </div>
                                            <div className="success-path__schema__item__text">
                                                <h3>{title}</h3>
                                                <p>{text}</p>
                                                {index ===
                                                    success_path_data?.length -
                                                        1 && (
                                                    <>
                                                        <strong>
                                                            Przeczytaj o niej
                                                            więcej:
                                                        </strong>
                                                        <Link
                                                            to="/win-win/"
                                                            className="btn btn-line btn-download-portfolio"
                                                        >
                                                            filozofia win-win
                                                        </Link>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    )
                                )}
                            </div>
                        </div>
                    </div>
                </section>

                <section className="single-offer-section-clients">
                    <div className="row">
                        <div className="col-xl-8 offset-xl-3 col-lg-9 offset-lg-3">
                            <div className="section-header aligncenter">
                                <h2 className="section-header__title">
                                    Zaufali nam
                                </h2>
                            </div>
                            <div className="row align-items-center justify-content-center">
                                <div className="col-md-3 col-sm-4 col-6 client-col">
                                    <div className="client-col__logo-wrapper">
                                        <img
                                            className="img-fluid client-col__logo"
                                            src={require("../assets/img/clients/thailand.png")}
                                            alt=""
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3 col-sm-4 col-6 client-col">
                                    <div className="client-col__logo-wrapper">
                                        <img
                                            className="img-fluid client-col__logo"
                                            src={require("../assets/img/clients/gmr.jpg")}
                                            alt=""
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3 col-sm-4 col-6 client-col">
                                    <div className="client-col__logo-wrapper">
                                        <img
                                            className="img-fluid client-col__logo"
                                            src={require("../assets/img/clients/komputronik.png")}
                                            alt=""
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3 col-sm-4 col-6 client-col">
                                    <div className="client-col__logo-wrapper">
                                        <img
                                            className="img-fluid client-col__logo"
                                            src={require("../assets/img/clients/krakow.png")}
                                            alt=""
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3 col-sm-4 col-6 client-col">
                                    <div className="client-col__logo-wrapper">
                                        <img
                                            className="img-fluid client-col__logo"
                                            src={require("../assets/img/clients/techland.png")}
                                            alt=""
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3 col-sm-4 col-6 client-col">
                                    <div className="client-col__logo-wrapper">
                                        <img
                                            className="img-fluid client-col__logo"
                                            src={require("../assets/img/clients/greenpoint.png")}
                                            alt=""
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3 col-sm-4 col-6 client-col">
                                    <div className="client-col__logo-wrapper">
                                        <img
                                            className="img-fluid client-col__logo"
                                            src={require("../assets/img/clients/steelseries.png")}
                                            alt=""
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3 col-sm-4 col-6 client-col">
                                    <div className="client-col__logo-wrapper">
                                        <img
                                            className="img-fluid client-col__logo"
                                            src={require("../assets/img/clients/imperial-stawowa.png")}
                                            alt=""
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <span className="anchor" id="kontakt"></span>
            <section className="single-offer-section-contact">
                <div className="form-wrapper">
                    <h2 className="single-offer-section-contact__title mb-40">
                        Skontaktuj się z nami! <br />
                        Wypełnij <span>formularz</span>
                    </h2>
                    <ContactFormDark locales={locales["pl"]} />
                </div>
                <img
                    className="img-fluid single-offer-section-contact__hand"
                    src={require("../assets/images/home-contact-hand-right.png")}
                    alt="Ręka"
                />
            </section>
        </Layout>
    );
};
export default ForeignCampaigns;
